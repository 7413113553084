import React from 'react';
import {
  Table, Input, Divider, Button, Modal, Form, List, notification, Upload, Icon, Pagination,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import idx from 'idx';
import * as userActions from '../../actions/user-action-types';

import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Dragger } = Upload;


const { Search } = Input;

class Cities extends React.PureComponent {
  state = {
    deleteModalvisible: false,
    deleteId: '',
    addModalvisible: false,
    editModalvisible: false,
    editData: null,
    address: '',
    latitude: 0,
    longitude: 0,
    name: '',
    editId: '',
    cityImagesId: '',

  };

  componentDidMount() {
    const { getCity } = this.props;
    getCity({ skip: 0 });
  }

  handleSearch = (value) => {
    const { getCity } = this.props;
    getCity({ search: value, skip: 0 });
  };

  onPaginationChange = (pageNumber) => {
    const { getCity } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getCity(request);
  }


  onGooglePlaceSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          address,
          // isAddressSelected: true,
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
      })
      .catch((error) => console.error('Error', error)); // eslint-disable-line no-console
  };

  showModal = (data, type) => {
    if (type === 'delete') {
      this.setState({
        deleteModalvisible: true,
        deleteId: data._id,
      });
    } else if (type === 'add') {
      this.setState({
        addModalvisible: true,
      });
    } else if (type === 'edit') {
      this.setState({
        editModalvisible: true,
        name: data.name,
        address: data.location.address,
        latitude: data.location.position.coordinates[1],
        longitude: data.location.position.coordinates[0],
        editId: data._id,
        cityImagesId: data.cityImage,
      });
    }
  };

  handleOk = (type) => {
    const { editCity, deleteCity, addCity } = this.props;
    const {
      deleteId, editData, name, cityImagesId, address, longitude, latitude, editId,
    } = this.state;

    const addCityObj = {
      name,
      city_image: cityImagesId[0],
      location: {
        address,
        position: {
          coordinates: [`${longitude}`, `${latitude}`],
        },
      },
    };

    if (type === 'delete') {
      deleteCity(deleteId);
      // this.setState({
      //   deleteModalvisible: false,
      // });
    } else if (type === 'add') {
      addCity(addCityObj);
      // this.setState({
      //   addModalvisible: false,
      // });
    } else if (type === 'edit') {
      const editCityObj = {
        name,
        city_image: cityImagesId,
        location: {
          address,
          position: {
            coordinates: [`${longitude}`, `${latitude}`],
          },
        },
      };
      editCity({ data: editCityObj, id: editId });
      // this.setState({
      //   editModalvisible: false,
      // });
    }
  };

  handleChange = (address) => {
    this.setState({
      address,
      // changeAddress: true,
    });
  };

  handleCancel = (type) => {
    if (type === 'delete') {
      this.setState({
        deleteModalvisible: false,
      });
    } else if (type === 'add') {
      this.setState({
        addModalvisible: false,
      });
    } else if (type === 'edit') {
      this.setState({
        editModalvisible: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      deleteCityStatus, addCityStatus, editCityStatus,

    } = this.props;

    if (
      deleteCityStatus === 'success'
      && prevProps.deleteCityStatus === 'requested'
    ) {
      // this.clearData();
      // this.props.closeEventModal();

      notification.success({
        key: 1,
        message: 'Delete Success',
        description: 'City deleted successfully .',
      });

      this.setState({
        deleteModalvisible: false,
      });
    }
    if (
      addCityStatus === 'success'
      && prevProps.addCityStatus === 'requested'
    ) {
      notification.success({
        key: 1,
        message: 'City Add Success',
        description: 'City added successfully .',
      });

      this.setState({
        addModalvisible: false,
      });
    }
    if (
      editCityStatus === 'success'
      && prevProps.editCityStatus === 'requested'
    ) {
      // this.clearData();
      // this.props.closeEventModal();

      notification.success({
        key: 1,
        message: 'Edit Success',
        description: 'City edited successfully .',
      });

      this.setState({
        editModalvisible: false,
      });
    }
  }

  onRemove = (info) => {
    const {
      cityImagesId,
    } = this.state;
    // const { deleteImage } = this.props;
    const infoArray = info.url ? info.url.split('/') : null;
    const file = idx(info, (_) => _.response.payload);

    if (file) {
      // When new file is added and removed on same time.
      // deleteImage(file._id);
    // } else {
      const images = [...cityImagesId];
      // const prevFiles = [...files];
      // When new file is removed from fileList array.
      const removedImageId = file._id;

      // deleteImage(removedImageId);

      // if (exists) {
      this.setState({
        cityImagesId: images.filter((id) => id !== removedImageId),
      });
    }
    // }
  };

  onImageUpload = (info) => {
    const { cityImagesId } = this.state;

    console.log('info', info);
    const file = idx(info, (_) => _.file.response.payload);

    if (file && info.fileList.length > 0) {
      let images = [...cityImagesId];
      const exists = images.some((id) => id === file._id);

      if (exists) {
        images = images.filter((id) => id !== file._id);
      } else {
        images.push(file._id);
      }

      this.setState({ cityImagesId: images });
    }
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    if (e.target.id === 'name') {
      this.setState({
        name: e.target.value,
      });
    }
  }

  render() {
    const { cities, userInfo, totalCount } = this.props;
    const {
      editData, address, name, cityImagesId,
    } = this.state;
    let token = '';
    if (userInfo) {
      token = userInfo.loginToken;
    }
    console.log(cityImagesId, 'cityImagesId');
    const props = {
      accept: '.png, .jpg, .jpeg',
      action: `${process.env.REACT_APP_SERVER_URL}/api/v1/files`,
      data: { field_name: 'city_image' },
      headers: { 'x-authorization': token },
      listType: 'picture-card',
      multiple: true,
      name: 'file',
      onChange: this.onImageUpload,
      onRemove: this.onRemove,
      showUploadList: {
        showDownloadIcon: false,
        showPreviewIcon: false,
        showRemoveIcon: true,
      },
    };

    const columns = [
      {
        title: 'City Name',
        dataIndex: 'name',
        key: 'name',
        // render: (text) => <a>{text.id}</a>,
      },
      {
        title: 'Created At',

        render: (text) => (
          <p>{moment.unix(text.createdAt / 1000).format('DD/MM/YYYY')}</p>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        // key: 'amount',
        render: (text) => <a>{text.address}</a>,
      },

      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (Status) => {
          if (Status === 1) {
            return <p>Active</p>;
          }
          if (Status === 2) {
            return <p>Remove/Deleted</p>;
          }
        },
      },
      {
        title: 'Action',
        render: (text) => (
          <span>
            <Button onClick={() => this.showModal(text, 'edit')}>Edit</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.showModal(text, 'delete')}>
              Delete
            </Button>
          </span>
        ),
      },
    ];
    return (
      <DashBoard selectKey="cities" {...this.props}>
        <div className="tableHeaderText">
          <div className="tableMainHead" style={{ width: '70%' }}>
            <p className="titleTextStyle">Cities</p>
          </div>
          <div style={{ width: '20%' }}>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
          <div>
            <Button type="primary" onClick={() => this.showModal([], 'add')}>
              Add City
            </Button>
          </div>
        </div>
        <br />
        <div>
          <Modal
            title="Delete this City?"
            visible={this.state.deleteModalvisible}
            onOk={() => this.handleOk('delete')}
            onCancel={() => this.handleCancel('delete')}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('delete')}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.handleOk('delete')}
              >
                Delete
              </Button>,
            ]}
          >
            <p>Do you want to delete this city ?</p>
          </Modal>
        </div>

        <div>
          <Modal
            title="Add City"
            visible={this.state.addModalvisible}
            onOk={() => this.handleOk('add')}
            onCancel={() => this.handleCancel('add')}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('add')}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.handleOk('add')}
              >
                Add
              </Button>,
            ]}
          >
            <div>
              <Form>
                <Form.Item label="City Name">
                  <div>
                    <Input id="name" value={name} onChange={this.handleInputChange} />
                  </div>
                </Form.Item>
                <Form.Item label="City Location">
                  <div>
                    <GooglePlacesAutocomplete
                      highlightFirstSuggestion
                      value={address}
                      onChange={this.handleChange}
                      onSelect={this.onGooglePlaceSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            placeholder="Search Location"
                            {...getInputProps({
                              className: 'location-search-input',
                              placeholder: 'Type Address',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {suggestions.length > 0 && (
                            <List
                              loading={loading}
                              itemLayout="horizontal"
                              dataSource={suggestions}
                              renderItem={(suggestion) => (
                                <List.Item
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description}
                                </List.Item>
                              )}
                            />
                            )}
                          </div>
                        </div>
                      )}
                    </GooglePlacesAutocomplete>
                  </div>
                </Form.Item>
                <Form.Item label="Add Images">
                  <Dragger
                    {...props}
                    // openFileDialogOnClick={tripImageId.length >= 0}
                  // defaultFileList={[{'https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}]}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single upload.
                    </p>
                  </Dragger>
                </Form.Item>
              </Form>

              {/* <div style={{ marginTop: '2em' }}>
                <Input addonBefore="City Location" />
              </div> */}
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            title="Edit City"
            visible={this.state.editModalvisible}
            onOk={() => this.handleOk('edit')}
            onCancel={() => this.handleCancel('edit')}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('edit')}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.handleOk('edit')}
              >
                Save
              </Button>,
            ]}
          >
            <div>
              <Form>
                <Form.Item label="City Name">
                  <div>
                    <Input id="name" value={name} onChange={this.handleInputChange} />
                  </div>
                </Form.Item>
                <Form.Item label="City Location">
                  <div>
                    <GooglePlacesAutocomplete
                      highlightFirstSuggestion
                      value={address}
                      onChange={this.handleChange}
                      onSelect={this.onGooglePlaceSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            placeholder="Search Location"
                            {...getInputProps({
                              className: 'location-search-input',
                              placeholder: 'Type Address',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {suggestions.length > 0 && (
                            <List
                              loading={loading}
                              itemLayout="horizontal"
                              dataSource={suggestions}
                              renderItem={(suggestion) => (
                                <List.Item
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description}
                                </List.Item>
                              )}
                            />
                            )}
                          </div>
                        </div>
                      )}
                    </GooglePlacesAutocomplete>
                  </div>
                </Form.Item>
              </Form>
            </div>

          </Modal>
        </div>
        <Table columns={columns} dataSource={cities} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>
    );
  }
}

const mapStateToProps = ({
  user: {
    cities, deleteCityStatus, userInfo, addCityStatus, editCityStatus, totalCount,
  },
}) => ({
  cities, deleteCityStatus, userInfo, addCityStatus, editCityStatus, totalCount,
});

const mapDispatchToProps = {
  getWithdraw: userActions.getWithdraw,
  getCity: userActions.getCity,
  editCity: userActions.editCity,
  deleteCity: userActions.deleteCity,
  addCity: userActions.addCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cities);
