import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Login from './Pages/Login';
import configureStore from './config/configure-store';
import 'react-toastify/dist/ReactToastify.css';
import UserManagement from './Pages/userManagement/UserManagement';
import SpotManagement from './Pages/spotManagement/spotManagement';
import Bookings from './Pages/booking/Booking';
import Reports from './Pages/reports/Reports';
import Withdraw from './Pages/withdraw/Withdraw';
import UserDetails from './Pages/userManagement/ViewDetails';
import SpotDetails from './Pages/spotManagement/SpotDetails';
import ReportDetails from './Pages/reports/ReportDetails';
import BookingDetails from './Pages/booking/bookingDetails';
import Profile from './Pages/profile/profile';
import Terms from './Pages/terms/terms';
import Privacy from './Pages/privacy/privacy';
import Faq from './Pages/faq/faq';
import Cities from './Pages/cities/cities';


const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <ToastContainer />
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/users" component={UserManagement} />
            <Route path="/spot-management" component={SpotManagement} />
            <Route path="/bookings" component={Bookings} />
            <Route path="/Reports" component={Reports} />
            <Route path="/withdraw" component={Withdraw} />
            <Route path="/user-details" component={UserDetails} />
            <Route path="/spot-details" component={SpotDetails} />
            <Route path="/report-details" component={ReportDetails} />
            <Route path="/booking-details" component={BookingDetails} />
            <Route path="/profile" component={Profile} />
            <Route path="/terms-conditions" component={Terms} />
            <Route path="/Privacy-policy" component={Privacy} />
            <Route path="/Faq" component={Faq} />
            <Route path="/Cities" component={Cities} />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
