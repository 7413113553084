import React from 'react';
import { Button, notification } from 'antd';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { connect } from 'react-redux';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';


class Terms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    const { getPages, pagesContent } = this.props;
    getPages('terms');
    this.setState({
      content: pagesContent.content,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      editPageStatus, pagesContent,
    } = this.props;
    if (prevProps.pagesContent.content !== pagesContent.content) {
      console.log('update');
      this.setState({ content: pagesContent.content });
    }
    if (
      editPageStatus === 'success'
      && prevProps.editPageStatus === 'requested'
    ) {
      notification.success({
        key: 1,
        message: 'Terms Content',
        description: 'Terms Content Saved successfully.',
      });
    }
  }

    handleModelChange = (e) => {
      this.setState({
        content: e,
      });
    }

    addSavePages = () => {
      const { addPages, editPages, pagesContent } = this.props;
      const { content } = this.state;
      const contentObj = {
        contentType: 'terms',
        content,
      };
      editPages(contentObj);
    }

    render() {
      const { pagesContent } = this.props;

      let buttonText = '';

      if (pagesContent === '') {
        buttonText = 'Add';
      } else {
        buttonText = 'Save';
      }
      return (
        <DashBoard
          selectKey="terms"


          {...this.props}
        >
          <div style={{ display: 'inline-flex', marginTop: '2em' }}>
            <div style={{ width: '107em' }}>
              <h2>Terms and Conditions</h2>
            </div>
            <div>
              <Button type="primary" onClick={this.addSavePages}>{buttonText}</Button>

            </div>
          </div>
          {pagesContent

          && (
          <FroalaEditorComponent
            tag="textarea"
            onModelChange={this.handleModelChange}
            model={pagesContent.content}
          />
          )}
        </DashBoard>

      );
    }
}

const mapStateToProps = ({
  user: {
    pagesContent, reports, userDetails, editPageStatus,
  },
}) => ({
  pagesContent, reports, userDetails, editPageStatus,
});

const mapDispatchToProps = {
  getPages: userActions.getPages,
  addPages: userActions.addPages,
  editPages: userActions.editPages,

};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
