import React from 'react';
import {
  Button, Modal, Form, Input, notification,
} from 'antd';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';


class Faq extends React.PureComponent {
  state = {
    loading: false,
    addVisible: false,
    answer: '',
    question: '',
    deleteVisible: false,
    deleteId: '',
    editVisible: false,
    editId: '',
  };

  componentDidMount() {
    const { getPages } = this.props;
    getPages('faq');
  }

  showModal = (data, type) => {
    if (type === 'add') {
      this.setState({
        addVisible: true,
      });
    } else if (type === 'delete') {
      this.setState({
        deleteVisible: true,
        deleteId: data,
      });
    } else if (type === 'edit') {
      this.setState({
        editVisible: true,
        editId: data._id,
        question: data.question,
        answer: data.answer,
      });
    }
  };

  handleOk = (type) => {
    const { addPages, deletePages, editPages } = this.props;
    const {
      question, answer, deleteId, editId,
    } = this.state;
    if (type === 'add') {
      const addPageObj = {
        contentType: 'faq',
        content: answer,
        question,
      };
      addPages(addPageObj);
    } else if (type === 'delete') {
      deletePages(deleteId);
    } else if (type === 'edit') {
      const editPageObj = {
        contentType: 'faq',
        content: answer,
        question,
        questionId: editId,
      };
      editPages(editPageObj);
    }
  };

  handleCancel = (type) => {
    if (type === 'add') {
      this.setState({ addVisible: false });
    } else if (type === 'delete') {
      this.setState({ deleteVisible: false });
    } else if (type === 'edit') {
      this.setState({ editVisible: false });
    }
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    if (e.target.id === 'question') {
      this.setState({
        question: e.target.value,
      });
    } else if (e.target.id === 'answer') {
      this.setState({
        answer: e.target.value,
      });
    }
  }


  componentDidUpdate(prevProps) {
    const {
      addPageStatus, deletePageStatus, editPageStatus,

    } = this.props;

    if (
      addPageStatus === 'success'
      && prevProps.addPageStatus === 'requested'
    ) {
      // this.clearData();
      // this.props.closeEventModal();

      notification.success({
        key: 1,
        message: 'FAQ Success',
        description: 'FAQ added successfully .',
      });

      this.setState({
        addVisible: false,
      });
    }
    if (
      deletePageStatus === 'success'
      && prevProps.deletePageStatus === 'requested'
    ) {
      // this.clearData();
      // this.props.closeEventModal();

      notification.success({
        key: 1,
        message: 'FAQ Delete',
        description: 'FAQ deleted successfully .',
      });

      this.setState({
        deleteVisible: false,
      });
    }

    if (
      editPageStatus === 'success'
      && prevProps.editPageStatus === 'requested'
    ) {
      // this.clearData();
      // this.props.closeEventModal();

      notification.success({
        key: 1,
        message: 'FAQ Edit',
        description: 'FAQ edit successfully .',
      });

      this.setState({
        editVisible: false,
      });
    }
  }

  render() {
    const {
      addVisible, loading, deleteVisible, editVisible, question, answer,
    } = this.state;

    const { pagesContent } = this.props;
    console.log(pagesContent);
    return (
      <DashBoard
        selectKey="faq"


        {...this.props}
      >
        <div className="tableHeaderText">
          <div className="tableMainHead" style={{ width: '90%' }}>
            <p className="titleTextStyle">FAQ</p>
          </div>

          <div>
            <Button type="primary" onClick={() => this.showModal([], 'add')}>
              Add FAQ
            </Button>
          </div>
        </div>
        <div>

          <Modal
            visible={deleteVisible}
            title="Delete FAQ"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('delete')}>
              Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => this.handleOk('delete')}>
              Delete
              </Button>,
            ]}
          >
            <p>Do you want to delete this city ? </p>
          </Modal>
        </div>
        <div>

          <Modal
            visible={addVisible}
            title="Add FAQ"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('add')}>
              Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => this.handleOk('add')}>
              Add
              </Button>,
            ]}
          >
            <Form>
              <Form.Item label="Question">
                <div>
                  <Input id="question" onChange={this.handleInputChange} />
                </div>
              </Form.Item>
              <Form.Item label="Answer">
                <div>
                  <Input id="answer" onChange={this.handleInputChange} />
                </div>
              </Form.Item>
            </Form>
          </Modal>
        </div>

        <div>

          <Modal
            visible={editVisible}
            title="Edit FAQ"
            onOk={() => this.handleOk('edit')}
            onCancel={() => this.handleCancel('edit')}
            footer={[
              <Button key="back" onClick={() => this.handleCancel('edit')}>
    Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => this.handleOk('edit')}>
    Save
              </Button>,
            ]}
          >
            <Form>
              <Form.Item label="Question">
                <div>
                  <Input id="question" value={question} onChange={this.handleInputChange} />
                </div>
              </Form.Item>
              <Form.Item label="Answer">
                <div>
                  <Input id="answer" value={answer} onChange={this.handleInputChange} />
                </div>
              </Form.Item>
            </Form>
          </Modal>
        </div>

        <div className="faq-wrap">
          {pagesContent.questionsAnswers && pagesContent.questionsAnswers.length > 0 ? pagesContent.questionsAnswers.map((item, index) => (
            <div className="faq-list">
              <h3>
Question
                {' '}
                {index + 1}
:-
                {' '}
                {item.question}
              </h3>
              <p>
Answer  :-
                {' '}
                {item.answer}
              </p>
              <Button onClick={() => this.showModal(item, 'edit')}>Edit</Button>
              <Button onClick={() => this.showModal(item._id, 'delete')}>Delete</Button>
            </div>
          ))
            : (
              <div>
                <h3 style={{ textAlign: 'center' }}>No FAQ Available</h3>
              </div>
            )}
        </div>
      </DashBoard>

    );
  }
}

const mapStateToProps = ({
  user: {
    pagesContent, reports, userDetails, editPageStatus, addPageStatus, deletePageStatus,
  },
}) => ({
  pagesContent, reports, userDetails, editPageStatus, addPageStatus, deletePageStatus,
});

const mapDispatchToProps = {
  getPages: userActions.getPages,
  addPages: userActions.addPages,
  editPages: userActions.editPages,
  deletePages: userActions.deletePages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
