import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './user';

const config = {
//   blacklist: [
//     'app',
//     'network',
//     'notifications',
//     'toast',
//   ],
  key: 'primary',
  storage,
//   transforms: [encryptor],
};

const reducers = persistCombineReducers(config, {
  user,
});

export default reducers;
