import React from 'react';
import { Button, notification } from 'antd';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { connect } from 'react-redux';
import DashBoard from '../dashboard/Dashboard';
import * as userActions from '../../actions/user-action-types';


class Privacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    const { getPages, pagesContent } = this.props;
    getPages('privacy_policy');
    this.setState({
      content: pagesContent.content,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      editPageStatus, pagesContent,
    } = this.props;
    const { content } = this.state;
    if (prevProps.pagesContent.content !== pagesContent.content) {
      console.log('update');
      this.setState({ content: pagesContent.content });
    }
    if (
      editPageStatus === 'success'
      && prevProps.editPageStatus === 'requested'
    ) {
      notification.success({
        key: 1,
        message: 'Policy Content',
        description: 'Policy Content Saved successfully.',
      });
    }
  }

    handleModelChange = (e) => {
      console.log('hi');
      this.setState({
        content: e,
      });
    }

    addSavePages = () => {
      const { addPages, editPages, pagesContent } = this.props;
      const { content } = this.state;
      const contentObj = {
        contentType: 'privacy_policy',
        content,
      };
      editPages(contentObj);
    }

    render() {
      const { pagesContent } = this.props;
      console.log(pagesContent, 'pagesContent');
      const { content } = this.state;
      let buttonText = '';
      if (pagesContent === '') {
        buttonText = 'Add';
      } else {
        buttonText = 'Save';
      }
      return (
        <DashBoard
          selectKey="privacy"


          {...this.props}
        >
          <div style={{ display: 'inline-flex', marginTop: '2em' }}>
            <div style={{ width: '107em' }}>
              <h2>Privacy and Policy</h2>
            </div>
            <div>
              <Button type="primary" onClick={this.addSavePages}>{buttonText}</Button>

            </div>
          </div>
          {pagesContent

&& (
<FroalaEditorComponent
  tag="textarea"
  onModelChange={this.handleModelChange}
  model={content}
/>
)}

        </DashBoard>

      );
    }
}

const mapStateToProps = ({
  user: {
    pagesContent, reports, userDetails, editPageStatus,
  },
}) => ({
  pagesContent, reports, userDetails, editPageStatus,
});

const mapDispatchToProps = {
  getPages: userActions.getPages,
  addPages: userActions.addPages,
  editPages: userActions.editPages,

};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
