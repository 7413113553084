import React from 'react';
import {
  Table, Divider, Button, Input, Pagination,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Search } = Input;

class Booking extends React.PureComponent {
  componentDidMount() {
    const { getBooking } = this.props;
    getBooking({ skip: 0 });
  }

  viewBookingDetails = (item) => {
    const { history } = this.props;
    history.push({
      pathname: '/booking-details',
      state: { detail: item },
    });
  }

  handleSearch = (value) => {
    const { getBooking } = this.props;
    getBooking({ search: value, skip: 0 });
  }

  onPaginationChange = (pageNumber) => {
    const { getBooking } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getBooking(request);
  }

  render() {
    const { bookings, totalCount } = this.props;
    const columns = [
      {
        title: 'Spot Name',
        dataIndex: 'spot',
        key: 'spot',
        render: (text) => <p>{text.title}</p>,
      },
      {
        title: 'Booking Period',
        render: (text) => <p>{`${moment.unix(text.checkInDateTime / 1000).format('DD/MM/YYYY')} ${' - '} ${moment.unix(text.checkOutDateTime / 1000).format('DD/MM/YYYY')}`}</p>,
      },
      {
        title: 'Price ',
        dataIndex: 'price',
        key: 'price',
        render: (text) => <p>{text.amount}</p>,

      },
      {
        title: 'User ',
        dataIndex: 'userId',
        key: 'userId',
        render: (text) => <p>{`${Methods.capitalizeFirstLetter(text.firstname)}${' '}${Methods.capitalizeFirstLetter(text.lastname)}`}</p>,

      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (Status) => {
          if (Status.value === 1) {
            return <p>Pending By User</p>;
          }
          if (Status.value === 2) {
            return <p>Completed By User</p>;
          }
          if (Status.value === 3) {
            return <p>Declined/Failed</p>;
          }
          if (Status.value === 4) {
            return <p>Pending Approval from Owner</p>;
          }
          if (Status.value === 5) {
            return <p>Accepted by Owner</p>;
          }
          if (Status.value === 6) {
            return <p>Rejected by Owner</p>;
          }
          if (Status.value === 7) {
            return <p> Auto Rejected</p>;
          }
          if (Status.value === 8) {
            return <p> Cancelled by User</p>;
          }
          if (Status.value === 9) {
            return <p>Completed</p>;
          }
        },

      },
      {
        title: 'Booking Date',

        render: (data) => <p>{`${moment.unix(data.createdAt / 1000).format('DD/MM/YYYY')}`}</p>,

      },
      {
        title: 'Action',
        render: (item) => (
          <span>

            <Divider type="vertical" />
            <Button onClick={() => this.viewBookingDetails(item)}>View</Button>
          </span>
        ),
      },
    ];

    return (
      <DashBoard
        selectKey="bookings"
        {...this.props}
      >
        <div className="tableHeaderText">
          <div className="tableMainHead">
            <p className="titleTextStyle">Bookings</p>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <br />
        <Table columns={columns} dataSource={bookings} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>

    );
  }
}

const mapStateToProps = ({ user: { getBookingStatus, bookings, totalCount } }) => ({ getBookingStatus, bookings, totalCount });

const mapDispatchToProps = {
  getBooking: userActions.getBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
