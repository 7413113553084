import React from 'react';
import {
  Table, Divider, Button, Input, Pagination,
} from 'antd';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Search } = Input;

class SpotManagement extends React.PureComponent {
  componentDidMount() {
    const { getSpot } = this.props;
    getSpot({ skip: 0 });
  }


  // componentDidUpdate(prevProps) {
  //   const {
  //     getSpotStatus, spots,
  //   } = this.props;

  //   if (getSpotStatus === 'success' && prevProps.getSpotStatus !== getSpotStatus) {
  //     this.setState({ spotData: spots });
  //   }
  // }

  viewSpotDetails = (item) => {
    const { history } = this.props;
    history.push({
      pathname: '/spot-details',
      state: { detail: item },
    });
  }

  handleSearch = (value) => {
    const { getSpot } = this.props;
    getSpot({ search: value, skip: 0 });
  }

  onPaginationChange = (pageNumber) => {
    const { getSpot } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getSpot(request);
  }

  render() {
    const { spots, totalCount } = this.props;
    const columns = [
      {
        title: 'Spot Name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Spot Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Address',
        dataIndex: 'location',
        render: (location) => <a>{location.address}</a>,

      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (Status) => {
          if (Status === 1) {
            return <p>Draft</p>;
          }
          if (Status === 2) {
            return <p>Active</p>;
          }
          if (Status === 3) {
            return <p>Inactive</p>;
          }
          if (Status === 4) {
            return <p>Removed/Deleted</p>;
          }
        },
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        render: (owner) => <p>{`${Methods.capitalizeFirstLetter(owner.firstname)}${' '}${Methods.capitalizeFirstLetter(owner.lastname)}`}</p>,
      },
      {
        title: 'Action',
        render: (item) => (
          <span>

            <Divider type="vertical" />
            <Button onClick={() => this.viewSpotDetails(item)}>View</Button>
          </span>
        ),
      },
    ];

    return (
      <DashBoard
        selectKey="spot_management"
        {...this.props}
      >

        <div className="tableHeaderText">
          <div className="tableMainHead">
            <p className="titleTextStyle">Spot Management</p>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <br />
        <Table columns={columns} dataSource={spots} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>

    );
  }
}
const mapStateToProps = ({ user: { getSpotStatus, spots, totalCount } }) => ({ getSpotStatus, spots, totalCount });

const mapDispatchToProps = {
  getSpot: userActions.getSpot,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotManagement);
