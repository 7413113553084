import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import MomentTimezone from 'moment-timezone';
import {
  LOGIN,
  loginRequested,
  loginSuccess,
  GET_USERS,
  getUsersRequested,
  getUsersSuccess,
  getUsersFailed,
  GET_SPOT,
  getSpotFailed,
  getSpotRequested,
  getSpotSuccess,
  GET_BOOKINGS,
  getBookingFailed,
  getBookingRequested,
  getBookingSuccess,
  GET_WITHDRAW,
  getWithdrawFailed,
  getWithdrawRequested,
  getWithdrawSuccess,
  GET_REPORTS,
  getReportFailed,
  getReportRequested,
  getReportSuccess,
  GET_USER_DETAILS,
  getUserDetailsFailed,
  getUserDetailsRequested,
  getUserDetailsSuccess,
  GET_REVIEWS,
  getReviewsFailed,
  getReviewsRequested,
  getReviewsSuccess,
  LOGOUT,
  logoutFailure,
  logoutSuccess,
  logoutRequested,
  ADD_PAGES,
  addPagesFailure,
  addPagesRequested,
  addPagesSuccess,
  GET_PAGES,
  getPagesFailure,
  getPagesRequested,
  getPagesSuccess,
  EDIT_PAGES,
  editPagesFailure,
  editPagesRequested,
  editPagesSuccess,
  GET_CITIES,
  getCityFailure,
  getCityRequested,
  getCitySuccess,
  EDIT_CITY,
  editCityFailure,
  editCityRequested,
  editCitySuccess,
  DELETE_CITY,
  deleteCityFailure,
  deleteCityRequested,
  deleteCitySuccess,
  ADD_CITY,
  addCityFailure,
  addCityRequested,
  addCitySuccess,
  DELETE_PAGES,
  deletePagesFailure,
  deletePagesRequested,
  deletePagesSuccess,

} from '../actions/user-action-types';
import httpClient from './http-client';

function* loginHandler({ payload: data }) {
  yield put(
    loginRequested({
      status: 'requested',
    }),
  );

  const token = yield select(({ user: { deviceToken } }) => deviceToken);

  const payload = {
    data: {
      ...data,
      deviceToken: token,
      deviceType: 'browser',
      timezone: MomentTimezone.tz.guess(),
    },
    method: 'post',
    url: 'users/login',
  };

  const { error, result } = yield call(httpClient, payload, true, false);

  if (error) {
    yield put(
      loginRequested({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      loginSuccess({
        userInfo: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getUsersHandler({ payload: data }) {
  yield put(
    getUsersRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data.search) {
    payload = {
      method: 'get',
      url: `admin/users?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `admin/users?skip=${data.skip}&limit=10`,
    };
  }


  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getUsersFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getUsersSuccess({
        userData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getSpotHandler({ payload: data }) {
  yield put(
    getSpotRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data.search) {
    payload = {
      method: 'get',
      url: `admin/spots?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `admin/spots?skip=${data.skip}&limit=10`,
    };
  }

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getSpotFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getSpotSuccess({
        spotData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getBookingsHandler({ payload: data }) {
  yield put(
    getBookingRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data.search) {
    payload = {
      method: 'get',
      url: `admin/bookings?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `admin/bookings?skip=${data.skip}&limit=10`,
    };
  }

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getBookingFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getBookingSuccess({
        bookingData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getReportsHandler({ payload: data }) {
  yield put(
    getReportRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data) {
    payload = {
      method: 'get',
      url: `admin/reports?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `admin/reports?skip=${data.skip}&limit=10`,
    };
  }

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getReportFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getReportSuccess({
        reportData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getWithdrawHandler({ payload: data }) {
  yield put(
    getWithdrawRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data.search) {
    payload = {
      method: 'get',
      url: `admin/withdrawals?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `admin/withdrawals?skip=${data.skip}&limit=10`,
    };
  }
  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getWithdrawFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getWithdrawSuccess({
        withdrawData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getUserDetailsHandler({ payload: data }) {
  yield put(
    getUserDetailsRequested({
      status: 'requested',
    }),
  );
  let payload = null;

  payload = {
    method: 'get',
    url: `users/detail/${data}`,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getUserDetailsFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getUserDetailsSuccess({
        userDetailsData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getReviewsHandler({ payload: data }) {
  yield put(
    getReviewsRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'get',
    url: `reviews?ownerId=${data}`,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getReviewsFailed({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getReviewsSuccess({
        reviewsData: result.payload,
        status: 'success',
      }),
    );
  }
}

function* addPagesHandler({ payload: data }) {
  yield put(
    addPagesRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'post',
    url: 'staticPages',
    data,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      addPagesFailure({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      addPagesSuccess({
        pagesContent: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getPagesHandler({ payload: data }) {
  yield put(
    getPagesRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'get',
    url: `staticPages/${data}`,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getPagesFailure({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      getPagesSuccess({
        pagesContent: result.payload,
        status: 'success',
      }),
    );
  }
}
function* editPagesHandler({ payload: data }) {
  yield put(
    editPagesRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'put',
    url: 'staticPages',
    data,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      editPagesFailure({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      editPagesSuccess({
        pagesContent: result.payload,
        status: 'success',
      }),
    );
  }
}

function* getCitiesHandler({ payload: data }) {
  yield put(
    getCityRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  if (data.search) {
    payload = {
      method: 'get',
      url: `cities?search=${data.search}&skip=${data.skip}&limit=10`,
    };
  } else {
    payload = {
      method: 'get',
      url: `cities?skip=${data.skip}&limit=10`,
    };
  }


  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      getCityFailure({
        status: 'failed',
      }),
    );
  } else {
    console.log(result);
    yield put(
      getCitySuccess({
        cities: result.payload,
        status: 'success',
      }),
    );
  }
}

function* deleteCityHandler({ payload: data }) {
  yield put(
    deleteCityRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'delete',
    url: `cities/${data}`,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      deleteCityFailure({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      deleteCitySuccess({
        data,
        status: 'success',
      }),
    );
  }
}

function* editCityHandler({ payload: data }) {
  yield put(
    editCityRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'put',
    url: `cities/${data.id}`,
    data: data.data,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      editCityFailure({
        status: 'failed',
      }),
    );
  } else {
    console.log(result);
    yield put(
      editCitySuccess({
        data: data.id,
        cities: result.payload,
        status: 'success',
      }),
    );
  }
}

function* addCityHandler({ payload: data }) {
  yield put(
    addCityRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'post',
    url: 'cities',
    data,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      addCityFailure({
        status: 'failed',
      }),
    );
  } else {
    console.log(result);
    yield put(
      addCitySuccess({
        cities: result.payload,
        status: 'success',
      }),
    );
  }
}

function* deleteFaqHandler({ payload: data }) {
  yield put(
    deletePagesRequested({
      status: 'requested',
    }),
  );
  let payload = null;
  payload = {
    method: 'delete',
    url: `staticPages/delete-faq/${data}`,
  };

  const { error, result } = yield call(httpClient, payload, true, true);

  if (error) {
    yield put(
      deletePagesFailure({
        status: 'failed',
      }),
    );
  } else {
    yield put(
      deletePagesSuccess({
        pagesContent: result.payload,
        status: 'success',
      }),
    );
  }
}


function* logoutHandler() {
  yield put(logoutRequested());
  const payload = {
    method: 'put',
    url: 'users/logout',
  };
  const { error } = yield call(httpClient, payload);

  if (error) {
    yield put(logoutFailure(error));
  } else {
    yield put(logoutSuccess());
    // setCookies(null);
    // Router.push('/');
  }
}


function* User() {
  yield all([
    takeLatest(LOGIN, loginHandler),
    takeLatest(GET_USERS, getUsersHandler),
    takeLatest(GET_SPOT, getSpotHandler),
    takeLatest(GET_BOOKINGS, getBookingsHandler),
    takeLatest(GET_REPORTS, getReportsHandler),
    takeLatest(GET_WITHDRAW, getWithdrawHandler),
    takeLatest(GET_USER_DETAILS, getUserDetailsHandler),
    takeLatest(GET_REVIEWS, getReviewsHandler),
    takeLatest(ADD_PAGES, addPagesHandler),
    takeLatest(GET_PAGES, getPagesHandler),
    takeLatest(EDIT_PAGES, editPagesHandler),
    takeLatest(GET_CITIES, getCitiesHandler),
    takeLatest(DELETE_CITY, deleteCityHandler),
    takeLatest(EDIT_CITY, editCityHandler),
    takeLatest(ADD_CITY, addCityHandler),
    takeLatest(DELETE_PAGES, deleteFaqHandler),
    takeLatest(LOGOUT, logoutHandler),
  ]);
}

export default User;
