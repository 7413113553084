import React from 'react';
import {
  Form, Input, Button,
} from 'antd';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Regex from '../utilities/regex';
import * as userActions from '../actions/user-action-types';

class Login extends React.Component {
  state = {
    email: '',
    error: {},
    password: '',
  };


  componentDidUpdate(prevProps) {
    const { loginStatus, history } = this.props;
    if (prevProps.loginStatus === 'requested' && loginStatus === 'success') {
      history.push('/users');
    }
  }


  onHandleChange = (event) => {
    const {
      value, name,
    } = event.target;

    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {
      email, password,
    } = this.state;
    const { login } = this.props;

    if (!Regex.email(email)) {
      this.setState({ error: { email: 'Enter a valid email' } });

      return;
    }

    if (!Regex.password(password)) {
      this.setState({ error: { password: 'Enter a valid password' } });
      return;
    }

    this.setState({ error: {} });

    const request = {
      deviceToken: '',
      deviceType: 'browser',
      email,
      password,
      role: 'admin',
    };
    login(request);
  }

  renderRedirect = () => {
    const { userInfo } = this.props;
    if (userInfo && userInfo.loginToken) {
      return <Redirect to="/users" />;
    } return null;
  }

  render() {
    const {
      email, password, error,
    } = this.state;

    const { loginStatus } = this.props;

    return (

      <Form>
        {
          this.renderRedirect()
        }
        <Form.Item
          validateStatus={!isEmpty(error.email) ? 'error' : ''}     //eslint-disable-line
          help={error.email}
        >
          <Input
            name="email"
            placeholder="EMAIL ADDRESS"
            value={email}
            onChange={this.onHandleChange}
            style={{
              height: '50px', width: '350px',
            }}
          />
        </Form.Item>
        <Form.Item
          validateStatus={!isEmpty(error.password) ? 'error' : ''}     //eslint-disable-line
          help={error.password}
        >
          <Input.Password
            placeholder="PASSWORD"
            name="password"
            value={password}
            onChange={this.onHandleChange}
            style={{ height: '50px' }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            onClick={this.onSubmit}
            className="signin-button"
            loading={loginStatus === 'requested'}
          >
            SIGN IN
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = ({ user: { loginStatus, userInfo } }) => ({ loginStatus, userInfo });

const mapDispatchToProps = {
  login: userActions.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
