/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Layout, Menu, Avatar, Dropdown, Icon,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  faCity, faParking, faLock, faExclamationCircle, faWallet, faSignal, faUser, faQuestionCircle, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user-action-types';

import theme from '../../constants/theme';
import Logo from '../../images/logo.svg';
import './Dashboard.css';

const { Header, Content, Sider } = Layout;

class dashboard extends React.PureComponent {
   handleMenuClick = (itemName) => {
     const { history } = this.props;
     switch (itemName) {
       case 'user_management':
         history.push('./users');
         break;
       case 'spot_management':
         history.push('/spot-management');
         break;
       case 'bookings':
         history.push('./bookings');
         break;
       case 'withdraw':
         history.push('./withdraw');
         break;
       case 'reports':
         history.push('./reports');
         break;
       case 'cities':
         history.push('./Cities');
         break;
       case 'terms':
         history.push('./terms-conditions');
         break;
       case 'privacy':
         history.push('./Privacy-policy');
         break;
       case 'faq':
         history.push('./Faq');
         break;

       default:
         break;
     }
   };


   getClassName = (key) => {
     const { selectKey } = this.props;
     let className;
     if (selectKey === key) {
       className = 'icon selectMenuItem';
     } else {
       className = 'icon unSelectMenuItem';
     }
     return className;
   }

   logoutAdmin = () => {
     const { logout } = this.props;
     logout();
   }

   renderRowAction = (event) => (
     <Menu>
       <Menu.Item>
         <a target="_blank" rel="noopener noreferrer" onClick={this.logoutAdmin}>
         Logout
         </a>
       </Menu.Item>

     </Menu>
   );

   componentDidUpdate(prevProps) {
     const { logoutStatus, history } = this.props;
     if (
       logoutStatus === 'success'
      && prevProps.logoutStatus !== logoutStatus
     ) {
       history.push('/');
     }
   }

   render() {
     const {
       userInfo, children, selectKey, history,
     } = this.props;

     return (
       <Layout>

         <Header
           className="header"
           style={{
             backgroundColor: theme.Colors.PRIMARY,
           }}
         >

           <img src={Logo} alt="" onClick={() => history.push('/users')} style={{ cursor: 'pointer' }} />
           <h2 className="slogan" onClick={() => history.push('/users')} style={{ cursor: 'pointer' }}>Parkao Admin</h2>
           {
        userInfo && (
        <div className="user-box">
          <Avatar size={40} icon="user" />
          <Dropdown overlay={this.renderRowAction}>
            <a className="ant-dropdown-link" href="#">
              <h2 className="userName">{`${userInfo.firstname} ${userInfo.lastname}`}</h2>
              <Icon type="down" />
            </a>
          </Dropdown>
,

        </div>
        )
}

         </Header>
         <Layout>
           <Sider width={200} style={{ background: '#fff' }}>
             <div className="menu-item-dashboard">
               <FontAwesomeIcon className="icon" style={{ marginLeft: 12 }} icon={faSignal} />
               <h3 style={{ margin: 8 }}>Dashboard</h3>
             </div>
             <hr />
             <Menu
               selectedKeys={[selectKey]}
               style={{ height: '100%', borderRight: 0 }}
             >
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('user_management')}
               >
                 <FontAwesomeIcon className={this.getClassName('user_management')} icon={faUser} />
                 <div className={selectKey === 'user_management' ? 'selectMenuItem' : 'unSelectMenuItem'}>
User Management

                 </div>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('spot_management')}
               >
                 <FontAwesomeIcon className={this.getClassName('spot_management')} icon={faParking} />
                 <div className={selectKey === 'spot_management' ? 'selectMenuItem' : 'unSelectMenuItem'}>Spot Management</div>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('bookings')}
               >
                 <FontAwesomeIcon className={this.getClassName('bookings')} icon={faLock} />
                 <span className={selectKey === 'bookings' ? 'selectMenuItem' : 'unSelectMenuItem'}>Booking</span>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('withdraw')}
               >
                 <FontAwesomeIcon className={this.getClassName('withdraw')} icon={faWallet} />
                 <div className={selectKey === 'withdraw' ? 'selectMenuItem' : 'unSelectMenuItem'}>Withdraw</div>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('reports')}
               >
                 <FontAwesomeIcon className={this.getClassName('reports')} icon={faExclamationCircle} />
                 <span className={selectKey === 'reports' ? 'selectMenuItem' : 'unSelectMenuItem'}>Reports</span>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('cities')}
               >
                 <FontAwesomeIcon className={this.getClassName('cities')} icon={faCity} />
                 <span className={selectKey === 'cities' ? 'selectMenuItem' : 'unSelectMenuItem'}>Cities</span>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('terms')}
               >
                 <FontAwesomeIcon className={this.getClassName('terms')} icon={faInfoCircle} />
                 <span className={selectKey === 'terms' ? 'selectMenuItem' : 'unSelectMenuItem'}>Terms and Conditions</span>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('privacy')}
               >
                 <FontAwesomeIcon className={this.getClassName('privacy')} icon={faExclamationCircle} />
                 <span className={selectKey === 'privacy' ? 'selectMenuItem' : 'unSelectMenuItem'}>Privacy and Policy</span>
               </div>
               <div
                 className="menu-item"
                 onClick={() => this.handleMenuClick('faq')}
               >
                 <FontAwesomeIcon className={this.getClassName('faq')} icon={faQuestionCircle} />
                 <span className={selectKey === 'faq' ? 'selectMenuItem' : 'unSelectMenuItem'}>FAQ</span>
               </div>
             </Menu>
           </Sider>
           <Layout style={{ padding: '0 24px 24px' }}>
             <Content
               style={{
                 background: '#fff',
                 margin: 0,
                 minHeight: 800,
               }}
             >
               {children}
             </Content>
           </Layout>
         </Layout>
       </Layout>
     );
   }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  logoutStatus: state.user.logoutStatus,
});
const mapDispatchToProps = {
  logout: userActions.logout,
};


export default connect(mapStateToProps, mapDispatchToProps)(dashboard);
