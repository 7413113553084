import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Avatar } from 'antd';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';


class SpotDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spotData: [],
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const data = history.location.state.detail;
    this.setState({
      spotData: data,
    });
  }


  onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  viewProfile = (data) => {
    const { history } = this.props;
    history.push({
      pathname: '/profile',
      state: { detail: data.owner },
    });
  }

  stateReturn= () => {
    const { spotData } = this.state;

    if (spotData.status === 1) {
      return <p>Draft</p>;
    }
    if (spotData.status === 2) {
      return <p>Active</p>;
    }
    if (spotData.status === 3) {
      return <p>Inactive</p>;
    }
    if (spotData.status === 4) {
      return <p>Removed/Deleted</p>;
    }
  }


  render() {
    const { history } = this.props;

    const data = history.location.state.detail;
    console.log(data, 'data');

    return (
      <DashBoard selectKey="spot_management" {...this.props}>
        <div className="shadowBox">
          <div className="spotParkingTitle">
            <h1 className="page-title">{data.title}</h1>
          </div>
          <div className="spotParkingDescription user-row">
            <p>
              {data.description}
            </p>
          </div>
          <br />
          <div className="spotParkinGrid user-row">
            {data.spotImages.length > 0
                        && data.spotImages.map((item) => (
                          <span>
                            <img src={Methods.download(item.fileId)} alt="" />
                          </span>
                        ))}
          </div>
          <br />
          <div className="spotCountry user-row">
            <h3>Address</h3>
            <p>{data.location.address}</p>
          </div>

          <br />
          <div className="spotPrice user-row">
            <h3>Price</h3>
            <div>

              <ul className="price-listing" style={{ listStyleType: 'none' }}>


                <li><h3>Per Day</h3></li>
                <li><h3>Per Hour</h3></li>
                <li><h3>Per Week</h3></li>
                <li><h3>Per Month</h3></li>
                <li><h3>Size</h3></li>

              </ul>
            </div>
            <div>
              {data.levels.length > 0
                        && data.levels[0].sizes.map((item) => (
                          <ul className="pricing" style={{ listStyleType: 'none' }}>

                            <li><h4>{item.price.perDay}</h4></li>
                            <li><h4>{item.price.perHour}</h4></li>
                            <li><h4>{item.price.perWeek}</h4></li>
                            <li><h4>{item.price.perMonth}</h4></li>
                            <li><h4>{item.value}</h4></li>
                          </ul>
                        ))}
            </div>
          </div>
          <br />
          <div className="spotAmenities user-row">
            <h3>Amentities</h3>
            <ul>
              <li>
                <span className="parkao-icon icon-full-screen" />

Park Size
              </li>
              <li>
                <span className="parkao-icon icon-lock" />
security
              </li>
              <li>
                <span className="parkao-icon icon-electric" />
                Electric
              </li>
              <li>
                <span className="parkao-icon icon-bolt" />
Instant Book
              </li>
              <li>
                <span className="parkao-icon icon-hours" />
Auto-Check-In
              </li>
              <li>
                <span className="parkao-icon icon-hand" />
Private Comm.
              </li>
              <li>
                <span className="parkao-icon icon-handicapped" />
Handicapped
              </li>
              <li>
                <span className="parkao-icon icon-garage" />
  Garage
              </li>
              <li>
                <span className="parkao-icon icon-parking" />
  Public
              </li>
              <li>
                <span className="parkao-icon icon-door-lock" />
  Electric Door
              </li>
              <li>
                <span className="parkao-icon icon-wc" />
  WC
              </li>
              <li>
                <FontAwesomeIcon className="AmentitiesIcon" icon={faUser} />
  Level
              </li>
            </ul>
          </div>
          <br />
          <div className="spotStatus user-row">
            <h3>
Spot Status

            </h3>
            <p>{this.stateReturn()}</p>
          </div>
          <br />
          <div className="spotOwnerWrap user-row last-one">
            <h2>Owner Information</h2>
            <div className="spotOwner">
              <span className="ownerProfileImg">
                {data.owner.profileImage === ''
                  ? <Avatar size={80} icon="user" />
                  : <img className="ownerProfileImg" src={Methods.download(data.owner.profileImage)} alt="" />}

              </span>
              <div>
                <h3>{`${Methods.capitalizeFirstLetter(data.owner.firstname)}${' '}${Methods.capitalizeFirstLetter(data.owner.lastname)}`}</h3>
                <p>{data.owner.email}</p>
              </div>
              <Button className="profileBtn" onClick={() => this.viewProfile(data)}>View Profile</Button>
            </div>
          </div>

        </div>
      </DashBoard>
    );
  }
}

export default SpotDetails;
