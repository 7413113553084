import {
  LOGIN_REQUESTED,
  SET_AUTHENTICATION_TOKEN,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  GET_USERS_SUCCESS,
  GET_SPOT_FAILED,
  GET_SPOT_REQUESTED,
  GET_SPOT_SUCCESS,
  GET_BOOKINGS_FAILED,
  GET_BOOKINGS_REQUESTED,
  GET_BOOKINGS_SUCCESS,
  GET_WITHDRAW_FAILED,
  GET_WITHDRAW_REQUESTED,
  GET_WITHDRAW_SUCCESS,
  GET_REPORTS_FAILED,
  GET_REPORTS_REQUESTED,
  GET_REPORTS_SUCCESS,
  GET_USER_DETAILS_FAILED,
  GET_USER_DETAILS_REQUESTED,
  GET_USER_DETAILS_SUCCESS,
  GET_REVIEWS_FAILED,
  GET_REVIEWS_REQUESTED,
  GET_REVIEWS_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUESTED,
  ADD_PAGES_FAILURE,
  ADD_PAGES_REQUESTED,
  ADD_PAGES_SUCCESS,
  GET_PAGES_FAILURE,
  GET_PAGES_REQUESTED,
  GET_PAGES_SUCCESS,
  EDIT_PAGES_FAILURE,
  EDIT_PAGES_REQUESTED,
  EDIT_PAGES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CITIES_REQUESTED,
  GET_CITIES_SUCCESS,
  EDIT_CITY_FAILURE,
  EDIT_CITY_REQUESTED,
  EDIT_CITY_SUCCESS,
  DELETE_CITY_FAILURE,
  DELETE_CITY_REQUESTED,
  DELETE_CITY_SUCCESS,
  ADD_CITY_FAILURE,
  ADD_CITY_REQUESTED,
  ADD_CITY_SUCCESS,
  DELETE_PAGES_FAILURE,
  DELETE_PAGES_REQUESTED,
  DELETE_PAGES_SUCCESS,

} from '../actions/user-action-types';

const initalState = {
  loginStatus: '',
  token: '',
  deviceToken: '',
  userInfo: null,
  usersData: null,
  getUserStatus: '',
  getSpotStatus: '',
  getBookingStatus: '',
  getReportStatus: '',
  getWithdrawStatus: '',
  spots: [],
  bookings: [],
  withdraws: [],
  reports: [],
  userDetailsStatus: '',
  userDetails: [],
  getReviewStatus: '',
  reviews: [],
  logoutStatus: '',
  totalCount: 0,
  addPageStatus: '',
  pagesContent: [],
  getPageStatus: '',
  editPageStatus: '',
  deletePageStatus: '',
  getCityStatus: '',
  editCityStatus: '',
  deleteCityStatus: '',
  addCityStatus: '',
  cities: [],

};

const user = (state = initalState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        loginStatus: action.payload.status,
      };

    case GET_USER_DETAILS_FAILED:
      return { ...state };

    case GET_USER_DETAILS_REQUESTED:
      return { ...state };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetailsStatus: action.payload.status,
        userDetails: action.payload.userDetailsData,
      };

    case GET_CITIES_FAILURE:
      return { ...state };

    case GET_CITIES_REQUESTED:
      return { ...state };

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        getCityStatus: action.payload.status,
        cities: action.payload.cities.results,
        totalCount: action.payload.cities.count,
      };


    case EDIT_CITY_FAILURE:
      return { ...state, editCityStatus: action.payload.status };

    case EDIT_CITY_REQUESTED:
      return { ...state, editCityStatus: action.payload.status };

    case EDIT_CITY_SUCCESS:
      const newEditCity = action.payload.cities;
      const orignalCityList = [...state.cities];
      const editCityArray = orignalCityList.filter((item) => action.payload.data !== item._id);
      const newArrayCities = [
        ...editCityArray,
        newEditCity,
      ];
      return {
        ...state,
        editCityStatus: action.payload.status,
        cities: newArrayCities,
      };


    case ADD_CITY_FAILURE:
      return { ...state, addCityStatus: action.payload.status };

    case ADD_CITY_REQUESTED:
      return { ...state, addCityStatus: action.payload.status };

    case ADD_CITY_SUCCESS:
      const newCity = action.payload.cities;
      const list = [...state.cities];
      console.log(newCity, 'newCity');
      const newCities = [
        ...list,
        newCity,
      ];

      return {
        ...state,
        addCityStatus: action.payload.status,
        cities: newCities,
      };

    case DELETE_CITY_FAILURE:
      return { ...state, deleteCityStatus: action.payload.status };

    case DELETE_CITY_REQUESTED:
      return { ...state, deleteCityStatus: action.payload.status };

    case DELETE_CITY_SUCCESS:
      const cityList = [...state.cities];
      const deleteArray = cityList.filter((item) => action.payload.data !== item._id);

      return {
        ...state,
        deleteCityStatus: action.payload.status,
        cities: deleteArray,
      };


    case ADD_PAGES_FAILURE:
      return { ...state, addPageStatus: action.payload.status };

    case ADD_PAGES_REQUESTED:
      return { ...state, addPageStatus: action.payload.status };

    case ADD_PAGES_SUCCESS:
      return {
        ...state,
        addPageStatus: action.payload.status,
        pagesContent: action.payload.pagesContent,
      };


    case DELETE_PAGES_FAILURE:
      return { ...state, deletePageStatus: action.payload.status };

    case DELETE_PAGES_REQUESTED:
      return { ...state, deletePageStatus: action.payload.status };

    case DELETE_PAGES_SUCCESS:
      return {
        ...state,
        deletePageStatus: action.payload.status,
        pagesContent: action.payload.pagesContent,
      };

    case EDIT_PAGES_FAILURE:
      return { ...state, editPageStatus: action.payload.status };

    case EDIT_PAGES_REQUESTED:
      return { ...state, editPageStatus: action.payload.status };

    case EDIT_PAGES_SUCCESS:
      return {
        ...state,
        editPageStatus: action.payload.status,
        pagesContent: action.payload.pagesContent,
      };


    case GET_PAGES_FAILURE:
      return { ...state, getPageStatus: action.payload.status };

    case GET_PAGES_REQUESTED:
      return { ...state, getPageStatus: action.payload.status };

    case GET_PAGES_SUCCESS:
      return {
        ...state,
        getPageStatus: action.payload.status,
        pagesContent: action.payload.pagesContent,
      };

    case GET_REVIEWS_FAILED:
      return { ...state };

    case GET_REVIEWS_REQUESTED:
      return { ...state };

    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        getReviewStatus: action.payload.status,
        reviews: action.payload.reviewsData.results,
      };

    case SET_AUTHENTICATION_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginStatus: action.payload.status,
        userInfo: action.payload.userInfo,
      };
    }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        userInfo: [],
        logoutStatus: 'success',
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        logoutStatus: 'failure',
      };

    case LOGOUT_REQUESTED:
      return {
        ...state,
        logoutStatus: 'requested',
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        getUserStatus: action.payload.status,
        usersData: action.payload.userData.results,
        totalCount: action.payload.userData.count,
      };
    case GET_SPOT_FAILED:
      return { ...state };

    case GET_SPOT_REQUESTED:
      return { ...state };

    case GET_SPOT_SUCCESS:
      return {
        ...state,
        getSpotStatus: action.payload.status,
        spots: action.payload.spotData.results,
        totalCount: action.payload.spotData.count,
      };

    case GET_BOOKINGS_FAILED:
      return { ...state };

    case GET_BOOKINGS_REQUESTED:
      return { ...state };

    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        getBookingStatus: action.payload.status,
        bookings: action.payload.bookingData.results,
        totalCount: action.payload.bookingData.count,
      };

    case GET_REPORTS_FAILED:
      return { ...state };

    case GET_REPORTS_REQUESTED:
      return { ...state };

    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        getReportStatus: action.payload.status,
        reports: action.payload.reportData.results,
        totalCount: action.payload.reportData.count,
      };

    case GET_WITHDRAW_FAILED:
      return { ...state };

    case GET_WITHDRAW_REQUESTED:
      return { ...state };

    case GET_WITHDRAW_SUCCESS:
      return {
        ...state,
        getWithdrawStatus: action.payload.status,
        withdraws: action.payload.withdrawData.results,
        totalCount: action.payload.withdrawData.count,
      };

    default:
      return state;
  }
};

export default user;
