import React from 'react';
import { Button, Avatar } from 'antd';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';


class ReportDetails extends React.PureComponent {
  onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  viewProfile = (data) => {
    const { history } = this.props;
    history.push({
      pathname: '/profile',
      state: { detail: data.reportedBy },
    });
  }

  render() {
    const { history } = this.props;
    const data = history.location.state.detail;

    return (
      <DashBoard selectKey="reports" {...this.props}>
        <div className="shadowBox">
          <div className="reportTitle">
            <h1 className="page-title">{data.reason}</h1>
          </div>
          <div className="spotParkingDescription user-row">
            <p>
              {data.description}
            </p>
          </div>

          <div className="spotOwnerWrap user-row reporterInfo">
            <h2>Reporter Information</h2>
            <div className="spotOwner">
              {data.reportedBy.profileImage === ''
                ? <Avatar size={80} icon="user" />
                : <img className="ownerProfileImg" src={Methods.download(data.reportedBy.profileImage)} alt="" />}

              <div>
                <h3>{`${Methods.capitalizeFirstLetter(data.reportedBy.firstname)}${' '}${Methods.capitalizeFirstLetter(data.reportedBy.lastname)}`}</h3>
                <p>{data.reportedBy.email}</p>
              </div>
              <Button className="profileBtn" onClick={() => this.viewProfile(data)}>View Profile</Button>
            </div>
          </div>
        </div>
      </DashBoard>
    );
  }
}

export default ReportDetails;
