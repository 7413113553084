import React from 'react';
import { Table, Input, Pagination } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Search } = Input;

class Withdraw extends React.PureComponent {
  componentDidMount() {
    const { getWithdraw } = this.props;
    getWithdraw({ skip: 0 });
  }

  handleSearch = (value) => {
    const { getWithdraw } = this.props;
    getWithdraw({ search: value, skip: 0 });
  }

  onPaginationChange = (pageNumber) => {
    const { getWithdraw } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getWithdraw(request);
  }


  render() {
    const { withdraws, totalCount } = this.props;

    const columns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentInfo',
        key: 'paymentInfo',
        render: (text) => <a>{text.id}</a>,
      },
      {
        title: 'Date',

        render: (text) => (
          <p>
            {moment.unix(text.updatedAt / 1000).format('DD/MM/YYYY')}

          </p>
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Owner',
        dataIndex: 'ownerId',
        key: 'ownerId',
        render: (text) => <p>{`${Methods.capitalizeFirstLetter(text.firstname)}${' '}${Methods.capitalizeFirstLetter(text.lastname)}`}</p>,

      },
      {
        title: 'Owner Email',
        dataIndex: 'ownerId',
        key: 'ownerId',
        render: (text) => <p>{text.email}</p>,

      },

      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (Status) => {
          if (Status.value === 1) {
            return <p>Pending</p>;
          }
          if (Status.value === 2) {
            return <p>Completed</p>;
          }
          if (Status.value === 3) {
            return <p> Failed / Rejected / Declined/ Cancelled</p>;
          }
          if (Status.value === 4) {
            return <p>Refunded</p>;
          }
          if (Status.value === 5) {
            return <p> Transferred to Owner</p>;
          }
        },

      },
    ];
    return (
      <DashBoard
        selectKey="withdraw"
        {...this.props}
      >
        <div className="tableHeaderText">
          <div className="tableMainHead">
            <p className="titleTextStyle">Withdraw</p>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <br />
        <Table columns={columns} dataSource={withdraws} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>

    );
  }
}

const mapStateToProps = ({ user: { getWithdrawStatus, withdraws, totalCount } }) => ({ getWithdrawStatus, withdraws, totalCount });

const mapDispatchToProps = {
  getWithdraw: userActions.getWithdraw,
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
