import React from 'react';
import {
  Table, Divider, Tag, Button, Input, Pagination,
} from 'antd';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Search } = Input;

class UserManagement extends React.PureComponent {
  componentDidMount() {
    const { getUsers } = this.props;
    getUsers({ skip: 0 });
  }


  viewUserDetails = (item) => {
    const { history } = this.props;
    history.push({
      pathname: '/user-details',
      state: { detail: item },
    });
  }

  handleSearch = (value) => {
    const { getUsers } = this.props;
    getUsers({ search: value, skip: 0 });
  }

  onPaginationChange = (pageNumber) => {
    const { getUsers } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getUsers(request);
  }

  render() {
    const { usersData, totalCount } = this.props;
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text) => <p>{text}</p>,
      },
      {
        title: 'First Name',

        render: (text) => <p>{Methods.capitalizeFirstLetter(text.firstname)}</p>,
      },
      {
        title: 'Last Name',

        render: (text) => <p>{Methods.capitalizeFirstLetter(text.lastname)}</p>,
      },
      {
        title: 'Role',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles) => {
          if (roles.length > 1) {
            return (
              <p>
                {roles[0].value}
/
                {roles[1].value}
              </p>
            );
          }
          return <p>{roles[0].value}</p>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (Status) => {
          if (Status === 1) {
            return <p>Active</p>;
          }
          if (Status === 2) {
            return <p>Blocked</p>;
          }
          if (Status === 3) {
            return <p>Removed/Deleted</p>;
          }
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (item) => (
          <span>

            <Divider type="vertical" />
            <Button onClick={() => this.viewUserDetails(item)}>View</Button>
          </span>
        ),
      },
    ];
    return (
      <DashBoard
        selectKey="user_management"
        {...this.props}
      >

        <div className="tableHeaderText">
          <div className="tableMainHead">
            <p className="titleTextStyle">User Management</p>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <br />
        <Table columns={columns} dataSource={usersData} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>

    );
  }
}
const mapStateToProps = ({ user: { getUserStatus, usersData, totalCount } }) => ({ getUserStatus, usersData, totalCount });

const mapDispatchToProps = {
  getUsers: userActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
