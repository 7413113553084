import React from 'react';
import { Switch } from 'antd';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';


class UserDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      isEnabled: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const data = history.location.state.detail;
    if (data.stripeConnectAccountStatus) {
      if (data.stripeConnectAccountStatus === 'verified') {
        this.setState({
          isEnabled: true,
        });
      } else {
        this.setState({
          isEnabled: false,
        });
      }
    }
    this.setState({
      userData: data,
    });
  }

  renderRole = (roles) => {
    if (roles) {
      if (roles.length > 1) {
        return (
          <p>
            {roles[0].value}
  /
            {roles[1].value}
          </p>
        );
      }
      return <p>{roles[0].value}</p>;
    }
  }

  render() {
    const { isEnabled } = this.state;
    const { history } = this.props;
    const userData = history.location.state.detail;
    let status = '';
    if (userData.status === 1) {
      status = 'Active';
    } else if (userData.status === 2) {
      status = 'Blocked';
    } else {
      status = 'Removed/Deleted';
    }
    return (
      <DashBoard
        selectKey="user_management"


        {...this.props}
      >
        {userData
        && (
        <div className="shadowBox">
          <div className="userFullname user-row">
            <h3>Full Name</h3>
            <p>{`${Methods.capitalizeFirstLetter(userData.firstname)}${' '}${Methods.capitalizeFirstLetter(userData.lastname)}`}</p>
          </div>
          <br />
          <div className="userRole user-row">
            <h3>Role</h3>

            { this.renderRole(userData.roles)}


          </div>
          <br />
          <div className="userStatus user-row">
            <h3>Status</h3>
            <p>{status}</p>

          </div>
          <br />
          <div className="userVerifySatus user-row last-one">
            <h3>
Verification Status
              {' '}

              <Switch checked={isEnabled} disabled />
            </h3>
            <p>{userData.stripeConnectAccountStatus}</p>

          </div>
        </div>
        )}

      </DashBoard>

    );
  }
}


export default UserDetails;
