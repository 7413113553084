
class Methods {
  static download(id) {
    return `${process.env.REACT_APP_MEDIA_DOWNLOAD_URL}${id}`;
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export default Methods;
