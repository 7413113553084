import React from 'react';
import { Button, Avatar } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

class BookingDetails extends React.PureComponent {
  onChange = (checked) => {
  };


  viewProfileOwner = (data) => {
    const { history } = this.props;
    history.push({
      pathname: '/profile',
      state: { detail: data.ownerId },
    });
  }

  viewProfileUser = (data) => {
    const { history } = this.props;
    history.push({
      pathname: '/profile',
      state: { detail: data.userId },
    });
  }

  renderPaymentSection = (data) => (data.paymentId.source === 1 ? (
    <div className="spotOwnerWrap user-row reporterInfo">
      <h3>Payment Information</h3>
      <p>
        <FontAwesomeIcon icon={faCreditCard} />
          Pay with Credit Card
        {' '}
      </p>
    </div>
  ) : data.paymentId.source === 2 ? (
    <div className="spotOwnerWrap user-row reporterInfo">
      <h3>Payment Information</h3>
      <p>
        <FontAwesomeIcon icon={faCreditCard} />
        {' '}
Pay with Apple Pay
      </p>
    </div>
  ) : data.paymentId.source === 3 ? (
    <div className="spotOwnerWrap user-row reporterInfo">
      <h3>Payment Information</h3>
      <p>
        <FontAwesomeIcon icon={faCreditCard} />
        {' '}
Pay with Google Pay
        {' '}
      </p>
    </div>
  ) : data.paymentId.source === 3 ? (
    <div className="spotOwnerWrap user-row reporterInfo">
      <h3>Payment Information</h3>
      <p>
        <FontAwesomeIcon icon={faWallet} />
        {' '}
Pay with Wallet
        {' '}
      </p>
    </div>
  ) : (
    <div className="spotOwnerWrap user-row reporterInfo">
      <h3>Payment Information</h3>
      <p>
        <FontAwesomeIcon icon={faCreditCard} />
        {' '}
Pay with Stripe
        {' '}
      </p>
    </div>
  ))

  render() {
    const { history } = this.props;
    const data = history.location.state.detail;
    const startDate = moment
      .unix(data.checkInDateTime / 1000)
      .format('YYYY-MM-DD');
    const endDate = moment
      .unix(data.checkOutDateTime / 1000)
      .format('YYYY-MM-DD');

    const diffDay = moment(startDate, 'YYYY-MM-DD').diff(
      moment(endDate, 'YYYY-MM-DD'),
      'days',
    );


    return (
      <DashBoard selectKey="bookings" {...this.props}>
        <div className="shadowBox">
          <div className="bookingTitle">
            <h1 className="page-title">{data.spot.title}</h1>
          </div>
          <div className="spotParkingDescription user-row last-one">
            <p>
              <span className="big-price">
$
                {data.price.amount}
              </span>
              {' '}
Per Night
            </p>
          </div>
          <br />
          <div className="bookingCard">
            <div className="col">
              <span className="booking-title">Check in</span>
              <span className="booking-date">
                {`${moment
                  .unix(data.checkInDateTime / 1000)
                  .format('DD/MM/YYYY')}`}

              </span>
              <span className="booking-time">
                {`${moment
                  .unix(data.checkInDateTime / 1000)
                  .format('hh:mm A')}`}

              </span>
            </div>
            <div className="col mid-col">
              <span className="booking-title">
                {`${-1
                * diffDay} ${'Days'} `}

              </span>
              <span className="booking-date">to</span>
            </div>
            <div className="col last-col">
              <span className="booking-title">Check OUT</span>
              <span className="booking-date">
                {`${moment
                  .unix(data.checkOutDateTime / 1000)
                  .format('DD/MM/YYYY')}`}

              </span>
              <span className="booking-time">
                {`${moment
                  .unix(data.checkOutDateTime / 1000)
                  .format('hh:mm A')}`}

              </span>
            </div>
          </div>
          <div className="spotOwnerWrap user-row reporterInfo">
            <h3>Booking Date</h3>
            <p>
              {`${moment
                .unix(data.createdAt / 1000)
                .format('DD/MM/YYYY')}`}

            </p>
          </div>
          <br />
          {data.paymentId
          && this.renderPaymentSection(data)}

          <div className="spotOwnerWrap user-row reporterInfo">
            <h2>User Information</h2>
            <div className="spotOwner">
              {data.userId.profileImage === ''
                ? <Avatar size={80} icon="user" />
                : <img className="ownerProfileImg" src={Methods.download(data.userId.profileImage)} alt="" />}

              <div>
                <h3>
                  {`${Methods.capitalizeFirstLetter(data.userId.firstname)}${' '}${
                    Methods.capitalizeFirstLetter(data.userId.lastname)
                  }`}

                </h3>
                <p>{data.userId.email}</p>
              </div>
              <Button className="profileBtn" onClick={() => this.viewProfileUser(data)}>View Profile</Button>
            </div>
          </div>
          {/*  */}
          <div className="spotOwnerWrap user-row reporterInfo last-one">
            <h2>Reporter Information</h2>
            <div className="spotOwner">
              {data.ownerId.profileImage === ''
                ? <Avatar size={80} icon="user" />
                : <img className="ownerProfileImg" src={Methods.download(data.ownerId.profileImage)} alt="" />}

              <div>
                <h3>
                  {`${Methods.capitalizeFirstLetter(data.ownerId.firstname)}${' '}${
                    Methods.capitalizeFirstLetter(data.ownerId.lastname)
                  }`}

                </h3>
                <p>{data.ownerId.email}</p>
              </div>
              <Button className="profileBtn" onClick={() => this.viewProfileOwner(data)}>View Profile</Button>
            </div>
          </div>
          {/*  */}
        </div>
      </DashBoard>
    );
  }
}

export default BookingDetails;
