import React from 'react';
import {
  Table, Divider, Input, Button, Pagination,
} from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user-action-types';
import DashBoard from '../dashboard/Dashboard';
import Methods from '../../Methods/index';

const { Search } = Input;

class Reports extends React.PureComponent {
  componentDidMount() {
    const { getReport } = this.props;
    getReport({ skip: 0 });
  }

  viewReportDetails = (item) => {
    const { history } = this.props;
    history.push({
      pathname: '/report-details',
      state: { detail: item },
    });
  }

  handleSearch = (value) => {
    const { getReport } = this.props;
    getReport({ search: value, skip: 0 });
  }

  onPaginationChange = (pageNumber) => {
    const { getReport } = this.props;
    const request = { skip: (pageNumber - 1) * 10 };

    getReport(request);
  }

  render() {
    const { reports, totalCount } = this.props;

    console.log(this.props.reports, 'reports');

    const columns = [
      {
        title: 'Type',
        render: (type) => {
          if (type.spotId) {
            return <p>Spot Type</p>;
          }
          if (type.bookingId) {
            return <p>Booking Type</p>;
          }
          if (type.userId) {
            return <p>User Type</p>;
          }
        },
      },
      {
        title: 'Report Reason',
        render: (data) => <p>{data.reason}</p>,
      },
      {
        title: 'Reporter',
        dataIndex: 'reportedBy',
        key: 'reportedBy',
        render: (name) => <p>{`${Methods.capitalizeFirstLetter(name.firstname)}${' '}${Methods.capitalizeFirstLetter(name.lastname)}`}</p>,
      },
      {
        title: 'Date',
        render: (data) => <p>{`${moment.unix(data.createdAt / 1000).format('DD/MM/YYYY')}`}</p>,
      },

      {
        title: 'Action',
        render: (item) => (
          <span>

            <Divider type="vertical" />
            <Button onClick={() => this.viewReportDetails(item)}>View</Button>
          </span>
        ),
      },
    ];
    // const { history } = this.props;
    return (
      <DashBoard
        selectKey="reports"
        {...this.props}
      >

        <div className="tableHeaderText">
          <div className="tableMainHead">
            <p className="titleTextStyle">Reports</p>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <br />
        <Table columns={columns} dataSource={reports} pagination={false} />
        <div style={{ textAlign: 'right', paddingTop: '1em' }}>
          {' '}
          <Pagination defaultCurrent={1} pageSize={10} total={totalCount} onChange={this.onPaginationChange} />
        </div>
      </DashBoard>

    );
  }
}

const mapStateToProps = ({
  user: {
    getReportStatus, reports, userDetails, totalCount,
  },
}) => ({
  getReportStatus, reports, userDetails, totalCount,
});

const mapDispatchToProps = {
  getReport: userActions.getReport,
  getUserDetails: userActions.getUserDetails,


};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
