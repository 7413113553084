import React from 'react';
import './public-layout.css';
import LoginImage from '../../static/images/login-img.png';

const PublicLayout = ({
  title, component: Component, props,
}) => (
  <div>
    <div className="flex-container">
      <div className="image-background">
        <img
          className="login-display-image"
          src={LoginImage}
          alt="login_image"
        />
      </div>
      <div className="login-text-container">
        <h1 className="login-heading">
          {title}
          <span className="main-shade"> Parkao</span>
        </h1>
        <Component {...props} />
      </div>
    </div>
  </div>
);

export default PublicLayout;
