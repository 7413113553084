import { createAction } from 'redux-actions';

export const LOGIN = 'LOGIN';
export const login = createAction(LOGIN);

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const loginRequested = createAction(LOGIN_REQUESTED);

export const SET_AUTHENTICATION_TOKEN = 'SET_AUTHENTICATION_TOKEN';
export const setAuthenticationToken = createAction(SET_AUTHENTICATION_TOKEN);

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = createAction(LOGIN_SUCCESS);


export const GET_USERS = 'GET_USERS';
export const getUsers = createAction(GET_USERS);

export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const getUsersFailed = createAction(GET_USERS_FAILED);

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const getUsersRequested = createAction(GET_USERS_REQUESTED);

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const getUsersSuccess = createAction(GET_USERS_SUCCESS);

export const GET_SPOT = 'GET_SPOT';
export const getSpot = createAction(GET_SPOT);

export const GET_SPOT_FAILED = 'GET_SPOT_FAILED';
export const getSpotFailed = createAction(GET_SPOT_FAILED);

export const GET_SPOT_REQUESTED = 'GET_SPOT_REQUESTED';
export const getSpotRequested = createAction(GET_SPOT_REQUESTED);

export const GET_SPOT_SUCCESS = 'GET_SPOT_SUCCESS';
export const getSpotSuccess = createAction(GET_SPOT_SUCCESS);

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const getBooking = createAction(GET_BOOKINGS);

export const GET_BOOKINGS_FAILED = 'GET_BOOKINGS_FAILED';
export const getBookingFailed = createAction(GET_BOOKINGS_FAILED);

export const GET_BOOKINGS_REQUESTED = 'GET_BOOKINGS_REQUESTED';
export const getBookingRequested = createAction(GET_BOOKINGS_REQUESTED);

export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const getBookingSuccess = createAction(GET_BOOKINGS_SUCCESS);

export const GET_WITHDRAW = 'GET_WITHDRAW';
export const getWithdraw = createAction(GET_WITHDRAW);

export const GET_WITHDRAW_FAILED = 'GET_WITHDRAW_FAILED';
export const getWithdrawFailed = createAction(GET_WITHDRAW_FAILED);

export const GET_WITHDRAW_REQUESTED = 'GET_WITHDRAW_REQUESTED';
export const getWithdrawRequested = createAction(GET_WITHDRAW_REQUESTED);

export const GET_WITHDRAW_SUCCESS = 'GET_WITHDRAW_SUCCESS';
export const getWithdrawSuccess = createAction(GET_WITHDRAW_SUCCESS);

export const GET_REPORTS = 'GET_REPORTS';
export const getReport = createAction(GET_REPORTS);

export const GET_REPORTS_FAILED = 'GET_REPORTS_FAILED';
export const getReportFailed = createAction(GET_REPORTS_FAILED);

export const GET_REPORTS_REQUESTED = 'GET_REPORTS_REQUESTED';
export const getReportRequested = createAction(GET_REPORTS_REQUESTED);

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const getReportSuccess = createAction(GET_REPORTS_SUCCESS);


export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const getUserDetails = createAction(GET_USER_DETAILS);

export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';
export const getUserDetailsFailed = createAction(GET_USER_DETAILS_FAILED);

export const GET_USER_DETAILS_REQUESTED = 'GET_USER_DETAILS_REQUESTED';
export const getUserDetailsRequested = createAction(GET_USER_DETAILS_REQUESTED);

export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const getUserDetailsSuccess = createAction(GET_USER_DETAILS_SUCCESS);

export const GET_REVIEWS = 'GET_REVIEWS';
export const getReviews = createAction(GET_REVIEWS);

export const GET_REVIEWS_FAILED = 'GET_REVIEWS_FAILED';
export const getReviewsFailed = createAction(GET_REVIEWS_FAILED);

export const GET_REVIEWS_REQUESTED = 'GET_REVIEWS_REQUESTED';
export const getReviewsRequested = createAction(GET_REVIEWS_REQUESTED);

export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const getReviewsSuccess = createAction(GET_REVIEWS_SUCCESS);


export const LOGOUT = 'LOGOUT';
export const logout = createAction(LOGOUT);

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const logoutFailure = createAction(LOGOUT_FAILURE);

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const logoutRequested = createAction(LOGOUT_REQUESTED);

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const ADD_PAGES = 'ADD_PAGES';
export const addPages = createAction(ADD_PAGES);

export const ADD_PAGES_FAILURE = 'ADD_PAGES_FAILURE';
export const addPagesFailure = createAction(ADD_PAGES_FAILURE);

export const ADD_PAGES_REQUESTED = 'ADD_PAGES_REQUESTED';
export const addPagesRequested = createAction(ADD_PAGES_REQUESTED);

export const ADD_PAGES_SUCCESS = 'ADD_PAGES_SUCCESS';
export const addPagesSuccess = createAction(ADD_PAGES_SUCCESS);

export const GET_PAGES = 'GET_PAGES';
export const getPages = createAction(GET_PAGES);

export const GET_PAGES_FAILURE = 'GET_PAGES_FAILURE';
export const getPagesFailure = createAction(GET_PAGES_FAILURE);

export const GET_PAGES_REQUESTED = 'GET_PAGES_REQUESTED';
export const getPagesRequested = createAction(GET_PAGES_REQUESTED);

export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const getPagesSuccess = createAction(GET_PAGES_SUCCESS);

export const EDIT_PAGES = 'EDIT_PAGES';
export const editPages = createAction(EDIT_PAGES);

export const EDIT_PAGES_FAILURE = 'EDIT_PAGES_FAILURE';
export const editPagesFailure = createAction(EDIT_PAGES_FAILURE);

export const EDIT_PAGES_REQUESTED = 'EDIT_PAGES_REQUESTED';
export const editPagesRequested = createAction(EDIT_PAGES_REQUESTED);

export const EDIT_PAGES_SUCCESS = 'EDIT_PAGES_SUCCESS';
export const editPagesSuccess = createAction(EDIT_PAGES_SUCCESS);

export const DELETE_PAGES = 'DELETE_PAGES';
export const deletePages = createAction(DELETE_PAGES);

export const DELETE_PAGES_FAILURE = 'DELETE_PAGES_FAILURE';
export const deletePagesFailure = createAction(DELETE_PAGES_FAILURE);

export const DELETE_PAGES_REQUESTED = 'DELETE_PAGES_REQUESTED';
export const deletePagesRequested = createAction(DELETE_PAGES_REQUESTED);

export const DELETE_PAGES_SUCCESS = 'DELETE_PAGES_SUCCESS';
export const deletePagesSuccess = createAction(DELETE_PAGES_SUCCESS);

export const GET_CITIES = 'GET_CITIES';
export const getCity = createAction(GET_CITIES);

export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';
export const getCityFailure = createAction(GET_CITIES_FAILURE);

export const GET_CITIES_REQUESTED = 'GET_CITIES_REQUESTED';
export const getCityRequested = createAction(GET_CITIES_REQUESTED);

export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const getCitySuccess = createAction(GET_CITIES_SUCCESS);

export const EDIT_CITY = 'EDIT_CITY';
export const editCity = createAction(EDIT_CITY);

export const EDIT_CITY_FAILURE = 'EDIT_CITY_FAILURE';
export const editCityFailure = createAction(EDIT_CITY_FAILURE);

export const EDIT_CITY_REQUESTED = 'EDIT_CITY_REQUESTED';
export const editCityRequested = createAction(EDIT_CITY_REQUESTED);

export const EDIT_CITY_SUCCESS = 'EDIT_CITY_SUCCESS';
export const editCitySuccess = createAction(EDIT_CITY_SUCCESS);

export const DELETE_CITY = 'DELETE_CITY';
export const deleteCity = createAction(DELETE_CITY);

export const DELETE_CITY_FAILURE = 'DELETE_CITY_FAILURE';
export const deleteCityFailure = createAction(DELETE_CITY_FAILURE);

export const DELETE_CITY_REQUESTED = 'DELETE_CITY_REQUESTED';
export const deleteCityRequested = createAction(DELETE_CITY_REQUESTED);

export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const deleteCitySuccess = createAction(DELETE_CITY_SUCCESS);

export const ADD_CITY = 'ADD_CITY';
export const addCity = createAction(ADD_CITY);

export const ADD_CITY_FAILURE = 'ADD_CITY_FAILURE';
export const addCityFailure = createAction(ADD_CITY_FAILURE);

export const ADD_CITY_REQUESTED = 'ADD_CITY_REQUESTED';
export const addCityRequested = createAction(ADD_CITY_REQUESTED);

export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';
export const addCitySuccess = createAction(ADD_CITY_SUCCESS);
