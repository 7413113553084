import React from 'react';
import {
  Layout, Avatar, Row, Col,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVenusMars, faEnvelope, faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import theme from '../../constants/theme';
import Logo from '../../images/logo.svg';
import ok from '../../images/ok.svg';
import * as userActions from '../../actions/user-action-types';
import Methods from '../../Methods/index';


const { Header } = Layout;

class Profile extends React.PureComponent {
  componentDidMount() {
    const { getUserDetails, history, getReviews } = this.props;
    const data = history.location.state.detail;

    getUserDetails(data._id);
    getReviews(data._id);
  }

  formateTime = (time) => {
    const s = time.split(':');
    return `${+s[0]} hours ${+s[1]} minutes`;
  }


  render() {
    const {
      userDetails, history, userInfo, reviews,
    } = this.props;
    const x = moment(userDetails.userRespondTime).format('hh:mm');
    console.log(userDetails, 'Methods.capitalizeFirstLetter');
    return (
      <div className="height100">
        <Header
          className="header"
          style={{
            backgroundColor: theme.Colors.PRIMARY,
          }}
        >
          {/* { */}

          <img src={Logo} alt="" onClick={() => history.push('/users')} style={{ cursor: 'pointer' }} />
          <h2 className="slogan" onClick={() => history.push('/users')} style={{ cursor: 'pointer' }}>Parkao Admin</h2>
          <div className="user-box">
            <Avatar size={40} icon="user" />
            {userInfo && <h2 className="userName">{`${userInfo.firstname} ${userInfo.lastname}`}</h2>}
          </div>
        </Header>
        {(userDetails && userDetails.length !== 0)

        && (
        <section className="ant-layout user-profile">
          <div className="container">
            <Row gutter={[{
              xs: 8, sm: 16, md: 24, lg: 30,
            }, 20]}
            >
              <Col span={10}>
                <div className="shadow-box">
                  <div className="user-sidebar">
                    <div className="imageWrap">
                      {userDetails.profileImage === ''
                        ? <Avatar size={100} icon="user" />
                        : <img className="imageStyle" src={Methods.download(userDetails.profileImage)} alt="" />}

                      <h2 className="userName">{`${Methods.capitalizeFirstLetter(userDetails.firstname)}${' '}${Methods.capitalizeFirstLetter(userDetails.lastname)}`}</h2>
                    </div>

                    <hr />
                    <h3>About me</h3>
                    <ul>
                      <li>
                        <i className="icon">
                          <FontAwesomeIcon className="AmentitiesIcon" icon={faUserClock} />

                        </i>
                        Response Time:
                        {this.formateTime(x)}
                      </li>
                      {userDetails.gender
                      && (
                      <li>
                        <i className="icon">
                          <FontAwesomeIcon className="AmentitiesIcon" icon={faVenusMars} />
                        </i>
                        {' '}
                        {userDetails.gender}
                      </li>
                      )}

                      <li>
                        <i className="icon">
                          <FontAwesomeIcon className="AmentitiesIcon" icon={faEnvelope} />
                        </i>
                        {' '}
                        {userDetails.email}
                      </li>
                    </ul>
                    <hr />
                    <h3>Verification</h3>
                    <ul>
                      <li>
                        <i className="icon">
                          <img src={ok} alt="" />
                        </i>
                        {' '}
                        Stripe Verification
                      </li>
                      <li>
                        <i className="icon">
                          <img src={ok} alt="" />
                        </i>
                        {' '}
                        Email
                      </li>
                      <li>
                        <i className="icon">
                          <img src={ok} alt="" />
                        </i>
                        {' '}
                        Phone
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col span={14}>
                {userDetails.isOwner


                && (
                <div className="shadow-box profile-content">

                  <h2 style={{ textAlign: 'center' }}>All Reviews</h2>

                  <ul className="user-profile-reviews">
                    {reviews.length > 0 && reviews.map((review, index) => (
                      <li>
                        {review.userId.profileImage === ''
                          ? <Avatar className="author-image" size={50} icon="user" />
                          : <img src={Methods.download(review.userId.profileImage)} className="author-image" alt="" />}
                        <h3>
                          {`${Methods.capitalizeFirstLetter(review.userId.firstname)}${' '}${
                            Methods.capitalizeFirstLetter(review.userId.lastname)
                          }`}

                          <span className="profile-stars">
                            <StarRatings
                              rating={review.ownerId.ownerRating}
                              starDimension="15px"
                              starSpacing="5px"
                              starRatedColor="#fdc271"
                            />
                          </span>
                        </h3>
                        <p>{review.spotId.title}</p>
                        <span className="review-date">
                          {`${moment
                            .unix(review.createdAt / 1000)
                            .format('DD/MM/YYYY')}`}
                        </span>
                        <p>
                          {review.review}
                        </p>
                      </li>
                    ))}
                    {reviews.length === 0
                    && <h1 className="noReviewsStyle">No Reviews are Available</h1>}

                  </ul>
                  <hr className="fullDivider" />

                </div>
                )}
              </Col>
            </Row>
          </div>
        </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user: { userDetails, reviews, userInfo } }) => ({ userDetails, reviews, userInfo });

const mapDispatchToProps = {
  getUserDetails: userActions.getUserDetails,
  getReviews: userActions.getReviews,

};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
